import "./utils/lazyloadImage";
import.meta.glob([
  "../public/images/**/*.{webp,jpg,png,jpeg,svg,avif}",
  "./webfonts/**/*",
]);
const pusher = new Pusher(import.meta.env.VITE_PUSHER_APP_KEY, {
  cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
  encrypted: true,
});
if (userId) {
  var channel = pusher.subscribe(`user.${userId}`);
  channel.bind("balance.updated", function (data) {
    Swal.fire({
      title: `Thông báo`,
      icon: "success",
      text: `Nạp thành công số  tiền ${formatNumber(data.amount)}`,
      confirmButtonColor: "rgb(97, 189, 109)",
      confirmButtonText: "Hoàn tất",
      showCancelButton: false,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        window.location.reload();
      }
    });
  });
} else {
  pusher.unsubscribe(`user.${userId}`);
}
function formatNumber(
  number,
  decimals = 0,
  decPoint = ".",
  thousandsSep = ",",
) {
  const options = {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  };

  const numberParts = new Intl.NumberFormat("en-US", options).formatToParts(
    number,
  );

  let formattedNumber = "";
  numberParts.forEach((part) => {
    switch (part.type) {
      case "integer":
      case "fraction":
        formattedNumber += part.value;
        break;
      case "group":
        formattedNumber += thousandsSep;
        break;
      case "decimal":
        formattedNumber += decPoint;
        break;
    }
  });

  return formattedNumber;
}
